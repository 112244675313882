import { graphql, Link, useStaticQuery } from 'gatsby';
import React from 'react';

import IntroText from '../components/intro-text';
import Layout from '../components/layout';
import MetaData from '../components/meta-data';

const JobsPage = (props) => {
  const title = 'Coiffeuse / Coiffeur';

  const { image } = useStaticQuery(
    graphql`
      {
        image: file(relativePath: { eq: "jobs.jpg" }) {
          childImageSharp {
            resize(width: 1920, quality: 100) {
              src
            }
          }
        }
      }
    `
  );

  return (
    <Layout path={props.location.pathname}>
      <MetaData
        title={title}
        description="Für meinen Coiffeur Salon am Rande von Thun suche ich per sofort oder nach Vereinbarung meine zukünftige Arbeitskollegin oder meinen zukünftigen Arbeitskollegen."
        image={image.childImageSharp.resize.src}
      />

      <IntroText title={title} subtitle="mit flexiblem Arbeitszeitmodell">
        Für meinen Coiffeur Salon am Rande von Thun suche ich per sofort oder
        nach Vereinbarung meine zukünftige Arbeitskollegin oder meinen
        zukünftigen Arbeitskollegen.
        <br />
        <br />
        <a
          href={'/assets/stelleninserat.pdf'}
          target="_blank"
          rel="noopener noreferrer"
          className="small"
        >
          <i className="fa fa-external-link"></i> Stelleninserat als PDF
          herunterladen
        </a>
      </IntroText>

      <IntroText
        subtitle="Werde ein Teil von Coiffeur Cocoon!"
        className="section-wrap-xs bg-light"
      >
        Bist du eine aufgestellte, lässige Persönlichkeit und auf der Suche nach
        einer neuen Herausforderung? Möchtest du deine Arbeitszeiten völlig
        flexibel einteilen, ohne dabei die Risiken eines eigenen Salons tragen
        zu müssen? Dann bist du bei mir genau richtig!
        <br />
        <br />
        Ich verfüge in meinem Salon über drei Stühle, welche ich gerne mit dir
        teilen möchte. Mein Geschäft erlaubt dir viel Freiraum, Kreativität und
        Selbständigkeit.
      </IntroText>

      <IntroText subtitle="Was dich auszeichnet" className="section-wrap-xs">
        Du konntest bereits einige Jahre Erfahrung in einem Friseursalon sammeln
        und verstehst deinen Beruf als Leidenschaft. Mit deiner offenen Art und
        deiner Professionalität gelingt es dir, unsere Kunden und Kundinnen auch
        emotional an unseren Salon zu binden – denn wir stehen für
        Kundenbeziehungen, die über unsere Dienstleistungen und Produkte
        hinausgehen.
      </IntroText>

      <IntroText
        subtitle="Was dich erwartet"
        className="section-wrap-xs bg-light"
      >
        Ein wertschätzender und kollegialer Umgang ist mir besonders wichtig. Du
        kannst dich mit deinen Ideen aktiv in die Weiterentwicklung des Salons
        einbringen und mit hohem Handlungsspielraum deine Leidenschaft
        entfalten. Nebst deinem Engagement als Coiffeuse/Coiffeur kannst du dein
        Fachwissen sowie deine Erfahrungen on the job als Visagist*in erweitern.
      </IntroText>

      <IntroText
        subtitle="Habe ich dein Interesse geweckt?"
        className="section-wrap-xs"
      >
        Dann würde ich dich gerne bei einem persönlichen Gespräch näher
        kennenlernen. Ich freue mich über deine Bewerbung an{' '}
        <a href="mailto:jobs@coiffeur-cocoon.ch">jobs@coiffeur-cocoon.ch</a>.
        Bei Fragen kannst du mich jederzeit{' '}
        <Link to="/kontakt/">kontaktieren</Link>.
      </IntroText>
    </Layout>
  );
};

export default JobsPage;
